import _ from "lodash";

// Write Alert messages
//
// alertType Values: "danger", "warning", "info", "success"
//
export function setAlerts(alertType: "danger" | "warning" | "info" | "success", alertMessage: string) {
    $("#alerts-placeholder").html(
        `
        <div class="alert alert-${alertType}"  style="box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
        <strong>${_.capitalize(alertType)}! </strong>${alertMessage}
        </div >
        `
    );
}

//
//
// function capitalize(s) {
//     return s.toLowerCase().replace(/\b./g, function (a) { return a.toUpperCase(); });
// }

// This is just required to avoid the error
// where all the app expects an init() function in all pages
//

// function init() {}
